import React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { getImage } from "gatsby-plugin-image"

import MainContainer from "../components/containers/MainContainer"
import HeadData from "../data/HeadData"
import PageHeaderImageBgNew from '../components/headers/PageHeaderImageBgNew'
import OneColumnPageLayout from "../components/layouts/OneColumnPageLayout"

const h3Style = 'text-gray-900 font-body text-2xl font-semibold mt-6 mb-3'
const pStyle = 'font-body text-lg text-gray-800 mb-6'

const AccessibilityPage = () => {
  const data = useStaticQuery(graphql`
    query PageHeaderImageBgNewQuery {
      imageBg: file(relativePath: { eq: "header/cat-building.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1024
          )
        }
      }
    }
  `)

  const imageBg = getImage(data.imageBg)

  return (
    <MainContainer>
      <HeadData
        title='Accessibility Statement'
      />

      <PageHeaderImageBgNew imageData={imageBg}>
        <div className='w-11/12 xl:max-w-screen-xl flex flex-col items-center'>
          <h1 className='font-display font-extrabold text-4xl md:text-5xl text-red-900 text-center tracking-wider'>
            Accessibility
          </h1>
        </div>
      </PageHeaderImageBgNew>

      <OneColumnPageLayout>
        <h2 className='font-display text-4xl md:text-5xl text-red-900 text-center tracking-wider leading-tight'>Command Access Technologies Accessibility Statement</h2>

        <h2 className={h3Style}>General</h2>
        <p className={pStyle}>Command Access Technologies strives to ensure that its services are accessible to people with disabilities. Command Access Technologies has invested a significant amount of resources to help ensure that its website is made easier to use and more accessible for people with disabilities, with the strong belief that every person has the right to live with dignity, equality, comfort and independence.</p>

        <h2 className={h3Style}>Accessibility on Command Access Technologies</h2>
        <p className={pStyle}>Command Access Technologies makes available the <a href='https://userway.org/' target='_blank' rel='noopener noreferrer' className="font-bold underline hover:text-red-900">UserWay Website Accessibility Widget</a> that is powered by a dedicated accessibility server. The software allows Command Access Technologies to improve its compliance with the Web Content Accessibility Guidelines (WCAG 2.1).</p>

        <h2 className={h3Style}>Enabling the Accessibility Menu</h2>
        <p className={pStyle}>Command Access Technologies accessibility menu can be enabled by clicking the accessibility menu icon that appears on the bottom left corner of the page. After triggering the accessibility menu, please wait a moment for the accessibility menu to load in its entirety.</p>

        <h2 className={h3Style}>Disclaimer</h2>
        <p className={pStyle}>Command Access Technologies continues its efforts to constantly improve the accessibility of its site and services in the belief that it is our collective moral obligation to allow seamless, accessible and unhindered use also for those of us with disabilities.</p>
        <p className={pStyle}>Despite our efforts to make all pages and content on Command Access Technologies fully accessible, some content may not have yet been fully adapted to the strictest accessibility standards. This may be a result of not having found or identified the most appropriate technological solution.</p>

        <h2 className={h3Style}>Here For You</h2>
        <p className={pStyle}>If you are experiencing difficulty with any content on commandaccess.com or require assistance with any part of our site, please contact us during normal business hours and we will be happy to assist.</p>

        <h2 className={h3Style}>Contact Us</h2>
        <p className={pStyle}>If you wish to report an accessibility issue, have any questions or need assistance, please <Link to="/contact" className="font-bold underline hover:text-red-900">click here</Link> to contact us.</p>
      </OneColumnPageLayout>
    </MainContainer>
  )
}

export default AccessibilityPage